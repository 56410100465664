@import "../../styles/variables/themeColors.scss";

.Message-logo {
  width: 100px;
}

.Message-container {
  margin: 40px;
}

.Message-alert{
  margin: 20px;
}
.Message-threshold {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  color: #000;
}

.Message-icon {
  margin-right: 5px;  
}

.Message-Container {
  max-width:800px;
  margin-top:20px;
}