@import "../../../styles/variables/themeColors.scss";

.AlertChip-MetricRow {
  font-size: 12px;
  border-bottom: 1px solid $hr-on-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 14px;
  padding: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.AlertChip-Badge {
  width: 15px;
  cursor: pointer;
}

.AlertChip-Panel {
  display: flex;
}

.AlertChip-EditAction {
  width: 20px;
  cursor: pointer;
}

.BrokenAlertChip-MetricRow {
  font-size: 12px;
  color: #dc3545;
  border-bottom: 1px solid $hr-on-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 14px;
  padding: 5px;
  margin-bottom: 5px;
  text-align: left;
}