@import "variables/app";
@import "variables/c3jscustom";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  //   margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: $text-dark;
  font-family: "Open Sans", sans-serif;
  background-color: $background-grey-light;
}

.Page-Layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;      
}
.Page-Main {
  display: flex;
  flex: 1;  
  .table thead th{
    font-weight: 400;    
  }
  .table th, .table td {
    padding-top: .4rem;
    padding-bottom: .4rem;
  }
  .form-control {
    height: calc(1.5em + 0.75rem + 0px);
  }
  textarea.form-control {
    height: auto;
  }
  .title{
    line-height: 1.3;
    font-size: 16px;
    font-weight: 600;    
  }
}

.Form-ButtonsContainer {
  padding:20px;
}

.Page-IconLarge {
  color: $icon-default;
  font-size: 64px;
}
.Page-emptyAlertsMessage {
    padding-top: 100px;
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  color: $background-white;
}

.btn-secondary {
  background-color: $color-secondary;
  border-color: $color-secondary;
  color: $background-white;
}

.btn-confirm {
  background-color: $color-confirm;
  border-color: $color-confirm;
  color: $background-white;
}

.btn-delete {
  background-color: $color-warn;
  border-color: $color-warn;
  color: $background-white;
}

.btn-cancel {
  background-color: $color-tertiary-a;
  border-color: $color-tertiary-a;
  color: $background-white;
}

.btn-menu {
  color: $text-dark;

}

.btn {
  font-size: 12px;
  line-height: 12px;
  padding: 5px 8px;
  margin: 0 5px;
  height: 24px;
}

.form-control{
  font-size: 13px;
}

.react-multi-email {
  border: 1px solid $border-dark-grey !important;
}

.react-multi-email.empty > span[data-placeholder] {
    color: $text-instruction !important;
}

.react-multi-email [data-tag] {
    margin: 0.14285714em 0.14285714em !important;
}

.form-label {
    color: $text-dark;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
}

.Page-loadingMessageContainer {
  display: flex;
  justify-content: center;
}

.Page-loadingMessage {
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  align-items: center;
  background-color: $background-grey-dark;
  color: $text-white;
  display: flex;
  height: 40px;
  justify-content: center;
  opacity: .8;
  width: 200px;
  z-index: 100000;
}