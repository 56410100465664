@import "../../styles/variables/themeColors.scss";

.AlertsSidePanel-SidebarContainer {
  background: $background-white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}
.AlertsSidePanel-SidebarContainer .scrollable {
  max-height: 767px;
  width: 260px;
  overflow: scroll;
  overflow-x:hidden;
  overflow-y: auto;
}

.AlertsSidePanel-Arrow {
	margin-left: 5px;
}

.AlertsSidePanel-NoAlertLabel {
	text-align: center;
	margin-top: 10px;
}

.AlertsSidePanel-TitleContainer {
	margin-bottom: 10px;
}

.AlertsSidePanel-Title {
	text-align: left;
	margin-left: 8px;
	font-size: 14px;
	color: $text-dark;
}

.AlertsSidePanel-Button {
	text-align: right;
}

.AlertsSidePanel-ExtraItemsButtonContainer {
	text-align: center;
	margin-top: 10px;
}
.AlertsSidePanel-ExtraItemsButton {
	color: $text-dark;
	text-decoration: none;
}