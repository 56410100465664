@import "../../styles/variables/themeColors.scss";


.NotificationGroup-Main {
  margin-top: 20px;
}

.NotificationGroup-ButtonToolbar {
  color: $icon-light;
}

.NotificationGroup-ActionButton {
  padding: 5px;
  cursor: pointer;
}

.NotificationGroup-EditIcon {
  margin-right: 5px;
  color: $icon-light;
}