@import "../../styles/variables/themeColors.scss";

.AlertBuilder-Container {
  max-width:800px;
  margin-top:20px;
}

.AlertBuilder-AlertConditionLabel {
  color: $text-instruction;
  margin-bottom: 10px;
}

.AlertBuilder-FrequencyButton {
  margin-right: 20px;
}

.AlertBuilder-FormInput-Invalid-Label {
  color: #dc3545;  
}

.AlertBuilder-SliderContainer {
  text-align: left;
  margin-left: 5px;  
}

.AlertBuilder-SliderMain {
  display: flex;
  align-content: space-between;
}

.AlertBuilder-SliderLeftLabel {
  margin-right: 10px;
  width: 60px;
}

.AlertBuilder-SliderRightLabel {
  margin-left: 15px;
  width: 60px;
}

#alertFrequency .slider-rangeHighlight {
  background: #498DFF;
}

#alertFrequency .slider-handle {
  background: #498DFF;
}

#alertFrequency  .slider-track {
  position: absolute;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, #D0D0D0 0%, #f9f9f9 100%);
  background-image: -o-linear-gradient(top, #D0D0D0 0%, #f9f9f9 100%);
  background-image: linear-gradient(to bottom, #D0D0D0 0%, #f9f9f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd0d0d0', endColorstr='#fff9f9f9', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

#alertFrequency .slider-selection {
  position: absolute;
  background-image: -webkit-linear-gradient(top, #498DFF 0%, #498DFF 100%);
  background-image: -o-linear-gradient(top, #498DFF 0%, #498DFF 100%);
  background-image: linear-gradient(to bottom, #498DFF 0%, #498DFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff498DFF', endColorstr='#ff498DFF', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}

.add-notification-group {
  margin-right: 0px;
  margin-bottom: 10px;
}

.buttonSpacer {
  margin-right: 28.5px;
}

.addConditionButton {
  margin-bottom: 20px;
  float: left;
}

.emptyTrashButton {
  width: 28px;
}

.thresholdPrefix {
  position: relative;
}

.thresholdPrefix > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.thresholdPrefix > input {
  padding-left: 20px;
  padding-right: 0;
}


.thresholdPrefix-right > i {
  right: 0;
}

.thresholdPrefix-right-select > i {
  right: 60px;
}

.thresholdPrefix-right-input > i {
  right: 12px;
}

.thresholdPrefix-right > input {
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
}

.invalidFiled{
  border: #dc3545 solid 1px;
  border-radius: 6px;
}

.invalidDefiniton{
  color: #dc3545;  
}