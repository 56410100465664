@import "../../styles/variables/app.scss";

.popover-body .row {
  padding: 15px;
  text-align: center;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  color: #9099a3;
}

.popover {
  border-radius: 0px;
  top: -20px;
  left: -192px;
  width: 176px;
}


/* Small screens */
@media all and (max-width: $smallScreen) {
  .popover {
    border-radius: 0px;
    top: -15px;
    left: -100px;
    width: 176px;
  }  
}

.confirmation-buttons {
  text-align: center;
}

.form-row {
  margin: 0px -12px -8px -12px;
}
.ConfirmationPopover-ButtonsContainer {
  background: $background-grey-light;
  padding: 10px;
}