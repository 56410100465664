@import "../../styles/variables/themeColors.scss";

.region-orange line{
  	stroke:#EF7E10;  
  	opacity: 1 !important;  
}

.region-orange text{
  	stroke:#EF7E10;  
  	font-weight: 100;  
}

.label-200 line{
  	stroke:#EF7E10;  
  	stroke-width: 12;
  	opacity: 0.3 !important;  
}

.label-200 text{
}

.Alerts-ChartContainer {
	background: $background-white;
	margin: 20px;
}
