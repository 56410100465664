//Primitive Colors
$color-primary: #498DFF; //new
$color-secondary: #7E8894; //new
$color-tertiary-a: #DCDFE0; //new
$color-help: #7E8894; //same as color-secondary
$color-confirm: #00AA4E; //new
$color-warn: #E34332; //new


// Text colors
$text-default: #9099a3;
$text-dark: #2b3134;
$text-instruction: #5F6872;
$text-white: #ffffff;

// Background Colors
$background-white: #ffffff;
$background-grey-light: #f8f8f8;
$panel-light-primary: #f2f2f2;
$background-grey-dark: #141c1f;

// Border colors
$border-side-panel: #c1c1c1;
$border-grey: #bcbcbc;
$border-light-grey: #e5e5e5;
$border-dark-grey: #ced4da;
$hr-on-light: #cbcbcb;

// Incon Colors
$icon-default: #616a74;
$icon-light: $text-default;
$icon-blue:	$color-primary;

$input-font-size: 12px;
$title-font-size: 16px;

// Fill Colors
$panel-dark-secondary: #3D5360;
$panel-dark-tertiary: #51788C;
