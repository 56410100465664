@import "../../styles/variables/app.scss";

.AlertsTable-ToggleContainer {
  text-align: center;
}

.AlertsTable-ToggleOn {
  color: $icon-blue;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;  
}

.AlertsTable-ToggleOff {
  color: $text-default;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;  
}

.AlertsTable-ReportLink {
  color: $text-dark;
  text-decoration: underline;
}

.AlertsTable-ReportIcon {
  margin-left: 5px;
}

.AlertsTable-Badge {
  margin-left: 5px;
  cursor: pointer;
}

.AlertsTable-ButtonGroup {
  color: $icon-light;
}

.AlertsTable-ButtonGroup-Broken {
  color: #dc3545;
}

.AlertsTable-ActionButton {
  padding: 5px;
  cursor: pointer;
}

.AlertsTable-AlertLink {
  color: $text-dark;
  text-decoration: none;
}

/* Small screens */
@media all and (max-width: $smallScreen) {
  .navigation {
    display: none;
  }
  .spacedCell {
    padding: 1px;
    padding-top: 1px;
    padding-left: 5px;
    text-align: left;
  }
  .borderedBlock {
    border: solid 1px $border-light-grey;
  }
  .AlertsTable-Badge {
    font-size: 14px;
  }
  .AlertsTable-ToggleOn {
    color: $icon-blue;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    font-size: 28px;
    div {
      font-size: 14px;
      color: $text-default;
      padding: 5px;
      float: right;
      padding-top: 11px;
    }  
  }
  .AlertsTable-ToggleOff {
    color: $text-default;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    font-size: 28px;
    min-width: 120px;
    div {
      font-size: 14px;
      color: $text-default;
      padding: 5px;
      float: right;
      padding-top: 11px;
    }  
  }
  .AlertsTable-ToggleContainer {
    text-align: left;    
  }
  .AlertsTable-ToggleMain {
    padding: 5px;
    padding-top: 0px;
    min-width: 135px
  }
  .AlertsTable-ActionsBlockContainer {
    text-align: right;
    padding-right: 0px;
  }
  .AlertsTable-ReportLink {
    font-weight: 600;
    padding-top: 4px; 
  }
  .AlertsTable-Condition {
    color: $text-default;
    display: flex;
  }
}
