@import "../../styles/variables/app.scss";

.AlertCard-header {
	background-color: $background-white;
}

.AlertCard-main {
	margin-bottom: 10px;
	margin-top: 10px;
}
.AlertCard-title-container {	
	margin-top: 0px;
	margin-left: 3px;
	text-align: left;
}
.AlertCard-title {	
	font-size: $title-font-size;
	font-weight: 600;	
}
.AlertCard-tooltip {	
	margin-top: 5px;
	margin-left: 25px;
	text-align: left;
	width: fit-content;
}
.AlertCard-timestamp {	
	text-align: left;
    font-weight: 400;
    line-height: 1.5;    
    font-size: 14px;
    width: auto;
    padding-right: 2px; 
}
.AlertCard-condition {	
	margin-right: 10px;
	color: #7E8894;
}
.AlertCard-value {	
	margin-right: 10px;
	color: #7E8894;
}
.AlertCard-ButtonToolbarContainer {
	margin-top: 10px;
}
.AlertCard-ButtonToolbar {	
	justify-content: flex-end;
}
.AlertCard-DetailsButton {	
	margin-right: 5px; 
	cursor: pointer;	
}
.AlertCard-Condition {
	display: flex;
}
/* Small screens */
@media all and (max-width: $smallScreen) {
	.AlertCard-ButtonToolbarContainer{
		display: none;
	}
}