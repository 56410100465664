@import "../../styles/variables/themeColors.scss";

.AlertNotificationCard-header {
	background-color: $background-white;
}

.AlertNotificationCard-main {
	margin-bottom: 10px;
	margin-top: 10px;
}
.AlertNotificationCard-title-container {	
	margin-top: 0px;
	margin-left: 3px;
	text-align: left;
}
.AlertNotificationCard-title {	
	font-size: $title-font-size;
	font-weight: 600;	
}
.AlertNotificationCard-tooltip {	
	margin-top: 5px;
	margin-left: 25px;
	text-align: left;
	width: fit-content;
}
.AlertNotificationCard-timestamp {	
	text-align: left;
    font-weight: 400;
    line-height: 1.5;    
    font-size: 14px;
    width: auto;
    padding-right: 2px; 
}
.AlertNotificationCard-condition {	
	margin-right: 10px;
	color: #7E8894;
}
.AlertNotificationCard-value {	
	margin-right: 10px;
	color: #7E8894;
}
.AlertNotificationCard-ButtonToolbarContainer {
	margin-top: 10px;
}
.AlertNotificationCard-ButtonToolbar {	
	justify-content: flex-end;
}
.AlertNotificationCard-DetailsButton {	
	margin-right: 5px; 
	cursor: pointer;	
}
.AlertNotificationCard-Condition {
	display: flex;
}
