.AlertBuilder {
  text-align: center;
}

.AlertBuilder-logo {
  height: 80px;
}

.AlertBuilder-header {
  background-color: #fff;
  height: 150px;
  padding: 20px;
  color: 000;
}

.AlertBuilder-title {
  font-size: 1.5em;
}

.AlertBuilder-intro {
  font-size: large;
}

@keyframes AlertBuilder-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.AlertBuilder-Select45 {
  width: 45%;
  margin-left: 10px;
}
.AlertBuilder-alignRight{
  text-align: right;
}

.AlertBuilder-FormContainer {
    max-width: 767px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid rgba(0,40,100,.12);
    margin: 10px;
}

.AlertBuilder-FormContent {
    background: rgb(244, 247, 251);
    padding: 10px;
}
.AlertBuilder-FormInput-Invalid {
  border-color: #dc3545;
}
.AlertBuilder-FormInput-Invalid-Feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.AlertBuilder-FormInput-Container {
  border-radius: 0.25rem;
  width: 100%;
}
.AlertBuilder-FormInput-Invalid-Container {
  border: #dc3545 solid 1px;  
}
.AlertBuilder-FormInput-Invalid-Label {
  color: #dc3545;  
}