@import "../../styles/variables/app.scss";

.Main-AlertsPanel {
    color: $text-dark;
    font-size: $input-font-size;
    background-color: $panel-light-primary;
    display: flex;
    flex-direction: column;
    margin: 0px;
    max-width: 280px;
    min-width: 280px;
    padding: 10px;
    width: 280px;
    border-right: 1px solid $border-side-panel;
}

.Main-AlertNotificationCardsContainer {
    margin-top: 10px;
}

.notification-exit {
  opacity: 1;
  transition: opacity 300ms ease, height 300ms ease 300ms;
}
.notification-exit.notification-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms ease-out, margin 300ms ease-in;
}

/* Small screens */
@media all and (max-width: $smallScreen) {
  .Main-AlertsPanel {
    display: none;    
  }  
}
