@import "../../styles/variables/themeColors.scss";

Nav .justify-content-end .nav-item {
  border-left: 0.01em solid lightgray;
}

.navbar {
  padding: 0px;
  display: block;
  font-family: "Open Sans";
}

.navbar .navbar-brand {
  float: left;
  height: 30px;
  padding: 10px 0 10px 15px;
  display: block;
  line-height: 20px;
  margin: 0px;
}

.navbar .navbar-brand img {
  width: 114px;
}

Nav .mr-auto {
  color: #2b3134;
  font-size: 13px;
  padding-left: 15px;
}

.navbar-light .navbar-nav .nav-link {
  color: #2b3134;
}

.navbar-nav .nav-link {
  padding-top: .9rem;
}

Nav .nav-item {
  font-size: 14px;
}

Nav .Header .active {
  height: 49px;
  border-bottom: solid 3px #498dff;
}

Nav .notifications .btn {
  padding: 0px;
}

Nav .notifications .badge {
  position: absolute;
  left: 50%;
  top: 6px;
}

.badge-pill {
  border-radius: 3px;
  padding: 3px 4px 0 4px;
  font-size: 9px;
  height: 15px;
}

Nav .notifications .fa {
  color: grey;
}

Nav .notifications .nav-items {
  display: inline;
}

Nav .support {
  min-width: fit-content;
  padding: 0px 5px;
  color: #2b3134;
  display: inline;
  float: left;
}

Nav .support a {
  padding: 15px 20px !important;
}

Nav .support .fa {
  color: grey;
}

.notify-footer {
  border-top: 1px solid #e2e2e2;
  position: relative;
}

.notify-footer p {
  color: green;
  margin-top: 1em;
}

.notify-footer .btn-outline-secondary {
  background-color: #f8f8f8;
  border: none;
}

.notify-footer .btn-outline-secondary:hover {
  background-color: lightgrey;
  color: grey;
}

.dropdown .dropdown-menu {
  display: block;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
}

.dropdown.show .dropdown-menu.show {
  display: block;
  visibility: visible;
  opacity: 1;
}

.dropdown-menu {
  min-width: 212px !important;
  border-radius: 0;
  margin-right: 5px;
  background-color: #1e292f;
  border: 1px solid #1e292f;
  max-height: 360px;
  padding-top: 10px;
}

.dropdown-item {
  color: #E3E3E3;
  font-size: 13px;
  font-weight: 300;
}

.dropdown-item.active {
  background-color: grey;
}

.dropdown-item:hover {
  color: #E3E3E3;
  background-color: #1e292f;
}

.dropdown-item:active {
  background-color: lightgrey;
}

.dropdown-divider {
  border-top: 1px solid #949B9E;
  margin: 6px 14px;
}

.notificationDropdown.dropdown-menu {
  background-color: white;
  border: solid 1px #cccccc;
  padding-bottom: 0px;
}

.notificationDropdown .dropdown-item {
  border-bottom: 1px solid $border-light-grey;
  font-size: 12px;
  color: #424b50;
  padding: 12px;
  font-weight: 400;
}

.notificationDropdown .dropdown-item:hover {
  color: #424b50;
  background-color: lightgrey;
}

.notificationSub {
  font-size: 12px;
  font-weight: 300;
  color: #9099a3;
}

.notificationBell {
  padding: 11px 23px 14px;
  display: inline-block;
  position: relative;
}

.notificationBell img {
  height: 20px;
  width: 17px;
}

.dropdown.nav-item {
  padding: 0 16px;
}

.Header {
  background-color: white;
  border-bottom: 1px solid $border-light-grey;
  cursor: auto;
  min-height: 49px;
  width: 100%;
  min-width: 380px;
  display: block;
}

.navbar-toggler {
  float: right;
  margin-top: 4px;
  margin-right: 4px;
}

.dropdown.nav-item {
  height: 50px;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.Reskin-ToggleOn {
  color: $color-primary;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  transform: scale(1.3);
  margin-top: 11px;
  margin-left: -15px;
}

.Reskin-ToggleOff {
  color: $text-default;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 11px;
  margin-left: 4px;
}

Nav .themeText {
  min-width: fit-content;
  padding: 0px 5px;
  color: #2b3134;
  display: inline;
  float: left;
  font-weight: 600;
  font-size: 12px;
  margin-top: 4px;
}

Nav .themeText a {
  padding: 15px 2px !important;
}

Nav .themeText .fa {
  color: grey;
  opacity: 0;
}

.toggle-line {
  border-left: 0 !important;
}